import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, FlatList, RefreshControl, StyleSheet, TextInput, Image, TouchableOpacity, Modal, ActivityIndicator, Alert, Button } from 'react-native';
import axios from 'axios';
import { Picker } from '@react-native-picker/picker';
import { useFocusEffect } from '@react-navigation/native';

export default function AuthorsScreen({ navigation }) {
    const [authors, setAuthors] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredAuthors, setFilteredAuthors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [editAuthor, setEditAuthor] = useState(null);
    const [deleteAuthorId, setDeleteAuthorId] = useState(null);

    const fetchAuthors = async () => {
        setRefreshing(true);
        try {
            const response = await axios.get('https://jiwimusic.fruitnasta.com/mind-cast/api/v1/authors');
            setAuthors(response.data.authors);
            setFilteredAuthors(response.data.authors);
        } catch (error) {
            console.error(error);
        }
        setRefreshing(false);
    };

    useFocusEffect(

        useCallback(() => {
            fetchAuthors();
        }, [])
    );

    const handleSearch = (query) => {
        setSearchQuery(query);
        if (query.length > 0) {
            const filteredData = authors.filter(author =>
                author.name.toLowerCase().includes(query.toLowerCase()) ||
                author.about.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredAuthors(filteredData);
        } else {
            setFilteredAuthors(authors);
        }
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            await axios.delete(`https://jiwimusic.fruitnasta.com/mind-cast/api/v1/authors/${id}`);
            fetchAuthors(); // Refresh the list after deletion
            setDeleteModalVisible(false);
            Alert.alert('Success', 'Author deleted successfully');
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            Alert.alert('Error', 'Failed to delete author');
        }
    };

    const handleEdit = async () => {
        if (!editAuthor) return;
        setLoading(true);
        try {
            await axios.patch(`https://jiwimusic.fruitnasta.com/mind-cast/api/v1/authors/${editAuthor.id}`, editAuthor);
            fetchAuthors(); // Refresh the list after update
            setLoading(false);
            setEditModalVisible(false);
            Alert.alert('Success', 'Author updated successfully');
        } catch (error) {
            console.error(error);
            setLoading(false);
            Alert.alert('Error', 'Failed to update author');
        }
    };

    const renderEditModal = () => {
        if (!editAuthor) return null;

        return (
            <Modal
                animationType="slide"
                transparent={true}
                visible={editModalVisible}
                onRequestClose={() => setEditModalVisible(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.label}>Name</Text>
                        <TextInput
                            style={styles.input}
                            value={editAuthor.name}
                            onChangeText={(text) => setEditAuthor({ ...editAuthor, name: text })}
                        />
                        <Text style={styles.label}>Category</Text>
                        <Picker
                            selectedValue={editAuthor.categories}
                            style={styles.input}
                            onValueChange={(itemValue) => setEditAuthor({ ...editAuthor, categories: itemValue })}
                        >
                            {['traditional', 'semi traditional', 'modern', 'old', 'romantic', 'sad', 'dong', 'lagne', 'baha', 'sohrai', 'dasai', 'nehor'].map((category) => (
                                <Picker.Item key={category} label={category} value={category} />
                            ))}
                        </Picker>
                        <Text style={styles.label}>Thumbnail Profile Image URL</Text>
                        <TextInput
                            style={styles.input}
                            value={editAuthor.thumbnailProfileImageURL}
                            onChangeText={(text) => setEditAuthor({ ...editAuthor, thumbnailProfileImageURL: text })}
                        />
                        <Image
                            style={styles.imagePreview}
                            source={{ uri: editAuthor.thumbnailProfileImageURL }}
                        />
                        <Text style={styles.label}>Profile Image URL</Text>
                        <TextInput
                            style={styles.input}
                            value={editAuthor.profileImageURL}
                            onChangeText={(text) => setEditAuthor({ ...editAuthor, profileImageURL: text })}
                        />
                        <Image
                            style={styles.imagePreview}
                            source={{ uri: editAuthor.profileImageURL }}
                        />
                        <Text style={styles.label}>About</Text>
                        <TextInput
                            style={styles.input}
                            value={editAuthor.about}
                            onChangeText={(text) => setEditAuthor({ ...editAuthor, about: text })}
                        />
                        <View style={styles.buttonContainer}>
                            <Button title="Update Author" onPress={handleEdit} />
                            <Button title="Cancel" onPress={() => setEditModalVisible(false)} />
                        </View>
                    </View>
                </View>
            </Modal>
        );
    };

    const renderDeleteModal = () => {
        return (
            <Modal
                animationType="slide"
                transparent={true}
                visible={deleteModalVisible}
                onRequestClose={() => setDeleteModalVisible(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text>Are you sure you want to delete this author?</Text>
                        <View style={styles.buttonContainer}>
                            <Button title="Yes" onPress={() => handleDelete(deleteAuthorId)} />
                            <Button title="No" onPress={() => setDeleteModalVisible(false)} />
                        </View>
                    </View>
                </View>
            </Modal>
        );
    };

    return (
        <View style={styles.container}>
            {loading && <ActivityIndicator size="large" color="#0000ff" />}
            <Text style={styles.countText}>Total Authors: {filteredAuthors.length}</Text>
            <TextInput
                style={styles.searchInput}
                placeholder="Search authors..."
                value={searchQuery}
                onChangeText={handleSearch}
            />
            <FlatList
                data={filteredAuthors}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) => (
                    <View style={styles.itemContainer}>
                        <Image style={styles.profileImage} source={{ uri: item.profileImageURL }} />
                        <View style={styles.textContainer}>
                            <Text style={styles.name}>{item.name}</Text>
                            <Text>{item.about}</Text>
                            <Text style={styles.details}>Categories: {item.categories.join(', ')}</Text>
                            <View style={styles.buttonGroup}>
                                <TouchableOpacity
                                    style={styles.button}
                                    onPress={() => {
                                        setDeleteAuthorId(item.id);
                                        setDeleteModalVisible(true);
                                    }}
                                >
                                    <Text style={styles.buttonText}>Delete</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={styles.button}
                                    onPress={() => {
                                        setEditAuthor(item);
                                        setEditModalVisible(true);
                                    }}
                                >
                                    <Text style={styles.buttonText}>Edit</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={styles.button}
                                    onPress={() => navigation.navigate('UploadSongs', { authorId: item.id })}
                                >
                                    <Text style={styles.buttonText}>Upload Song</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                )}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={fetchAuthors} />
                }
            />
            {renderEditModal()}
            {renderDeleteModal()}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
    },
    countText: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    searchInput: {
        height: 40,
        borderColor: '#ccc',
        borderWidth: 1,
        paddingHorizontal: 10,
        marginBottom: 10,
        borderRadius: 5,
    },
    itemContainer: {
        flexDirection: 'row',
        marginBottom: 10,
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
    },
    profileImage: {
        width: 90,
        height: 90,
        marginRight: 10,
    },
    textContainer: {
        flex: 1,
    },
    name: {
        fontWeight: 'bold',
    },
    details: {
        marginTop: 5,
    },
    buttonGroup: {
        flexDirection: 'row',
        marginTop: 10,
    },
    button: {
        backgroundColor: '#007BFF',
        padding: 10,
        borderRadius: 5,
        marginRight: 5,
    },
    buttonText: {
        color: '#fff',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        width: '80%',
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
    },
    label: {
        fontSize: 16,
        marginBottom: 5,
    },
    input: {
        height: 40,
        borderColor: '#ccc',
        borderWidth: 1,
        marginBottom: 10,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    imagePreview: {
        width: 100,
        height: 100,
        marginBottom: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    },
});
