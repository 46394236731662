import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Button, StyleSheet, Picker, Image, TouchableOpacity, ActivityIndicator, Modal, ScrollView, Clipboard, Alert } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { storage } from '../firebase';  // Make sure to adjust the path according to your project structure
import axios from 'axios';
import Toast from 'react-native-toast-message';

export default function UploadSongScreen({ route, navigation }) {
    const { authorId } = route.params;
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('modern');
    const [stars, setStars] = useState('5');
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [mp3File, setMp3File] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [currentLink, setCurrentLink] = useState('');
    const [author, setAuthor] = useState(null);
    const [thumbnailSuccess, setThumbnailSuccess] = useState('');
    const [imageSuccess, setImageSuccess] = useState('');

    useEffect(() => {
        const fetchAuthor = async () => {
            try {
                const response = await axios.get(`https://jiwimusic.fruitnasta.com/mind-cast/api/v1/authors/${authorId}`);
                setAuthor(response.data.author);
            } catch (error) {
                console.error(error);
                Alert.alert('Error', 'Failed to fetch author data');
            }
        };

        fetchAuthor();
    }, [authorId]);

    const handleFilePick = async (setFile) => {
        let result = await DocumentPicker.getDocumentAsync({
            type: 'audio/mpeg',
            copyToCacheDirectory: false,
        });
        console.log("mp3", result)
        if (!result.canceled) {
            setFile(result.assets[0] || result);
        }
    };

    const handleImagePick = async (setFile, setUrl, folder, setSuccess) => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
            base64: true
        });

        if (!result.canceled) {
            const image = result.assets[0] || result; // Handle web and native cases
            setLoading(true);
            try {
                const downloadURL = await uploadBase64ToFirebase(image.uri, folder);
                setFile(image);
                setUrl(downloadURL);
                setCurrentLink(downloadURL);
                setSuccess('Upload successful');
            } catch (error) {
                console.error(error);
                setCurrentLink('Failed to upload image');
                setSuccess('Upload failed');
            }
            setLoading(false);
        }
    };

    const uploadBase64ToFirebase = async (base64, folder) => {
        const blob = await fetch(base64).then(res => res.blob());
        const ext = base64.split(';')[0].split('/')[1]; // Extract the file extension
        const ref = storage.ref().child(`${folder}/${title}.${ext}`);
        await ref.put(blob);
        return await ref.getDownloadURL();
    };

    const deleteFromFirebase = async (url) => {
        const ref = storage.refFromURL(url);
        await ref.delete();
    };

    const handleDeleteThumbnail = async () => {
        try {
            await deleteFromFirebase(thumbnailUrl);
            setThumbnailUrl('');
            setThumbnailFile(null);
            Alert.alert('Success', 'Thumbnail image deleted successfully');
            setThumbnailSuccess('');
        } catch (error) {
            console.error(error);
            Alert.alert('Error', 'Failed to delete thumbnail image');
        }
    };

    const handleDeleteImage = async () => {
        try {
            await deleteFromFirebase(imageUrl);
            setImageUrl('');
            setImageFile(null);
            Alert.alert('Success', 'Image deleted successfully');
            setImageSuccess('');
        } catch (error) {
            console.error(error);
            Alert.alert('Error', 'Failed to delete image');
        }
    };

    const dataURLtoBlob = (dataurl) => {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    const handleSubmit = async () => {
        if (!title || !description || !thumbnailFile || !imageFile || !mp3File) {
            setCurrentLink("Please fill all fields and upload all files");
            setModalVisible(true);
            return;
        }

        setLoading(true);

        const mp3Blob = dataURLtoBlob(mp3File.uri);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('thumbnailImageURL', thumbnailUrl);
        formData.append('category', category);
        formData.append('stars', stars);
        formData.append('imageURL', imageUrl);
        formData.append('file', mp3Blob, mp3File.name);

        try {
            const response = await axios.post(
                `https://jiwimusic.fruitnasta.com/mind-cast/api/v1/authors/${authorId}/podcasts`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setCurrentLink("Podcast uploaded successfully");
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Podcast uploaded successfully 👋',
                position: 'top',
            });
            // Clear the form
            setTitle('');
            setDescription('');
            setCategory('modern');
            setStars('5');
            setThumbnailFile(null);
            setImageFile(null);
            setMp3File(null);
            setThumbnailUrl('');
            setImageUrl('');
        } catch (error) {
            console.error(error);
            setCurrentLink("Failed to upload podcast");
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Failed to upload podcast 😞',
                position: 'top',
            });
        }

        setLoading(false);
    };

    const handleCopyToClipboard = () => {
        Clipboard.setString(currentLink);
        setModalVisible(false);
    };

    const handleCopyIdToClipboard = () => {
        Clipboard.setString(authorId);
        Alert.alert('Copied', 'Author ID copied to clipboard');
    };

    return (
        <ScrollView contentContainerStyle={styles.scrollContainer}>
            <View style={styles.container}>
                {author && (
                    <View>
                        <Text style={styles.label}>Uploading for: {author.name}</Text>
                        <Text style={styles.label}>Author ID: {authorId}</Text>
                        <TouchableOpacity style={styles.copyButton} onPress={handleCopyIdToClipboard}>
                            <Text style={styles.copyButtonText}>Copy ID</Text>
                        </TouchableOpacity>
                        <Image source={{ uri: author.profileImageURL }} style={styles.profileImage} />
                    </View>
                )}
                <Text style={styles.label}>Title</Text>
                <TextInput
                    style={styles.input}
                    value={title}
                    onChangeText={setTitle}
                />

                <Text style={styles.label}>Description</Text>
                <TextInput
                    style={styles.input}
                    value={description}
                    onChangeText={setDescription}
                />

                <Text style={styles.label}>Category</Text>
                <Picker
                    selectedValue={category}
                    style={styles.input}
                    onValueChange={(itemValue) => setCategory(itemValue)}
                >
                    {['traditional', 'semi traditional', 'modern', 'old', 'romantic', 'sad', 'dong', 'lagne', 'baha', 'sohrai', 'dasai', 'nehor'].map((category) => (
                        <Picker.Item key={category} label={category} value={category} />
                    ))}
                </Picker>

                <Text style={styles.label}>Stars</Text>
                <Picker
                    selectedValue={stars}
                    style={styles.input}
                    onValueChange={(itemValue) => setStars(itemValue)}
                >
                    {[1, 2, 3, 4, 5].map((star) => (
                        <Picker.Item key={star} label={`${star}`} value={star} />
                    ))}
                </Picker>

                <Text style={styles.label}>Thumbnail Image</Text>
                <View style={styles.imageContainer}>
                    <TouchableOpacity style={styles.fileInput} onPress={() => handleImagePick(setThumbnailFile, setThumbnailUrl, 'MusicThumbnail', setThumbnailSuccess)} disabled={!title}>
                        <Text>{thumbnailFile ? thumbnailFile.fileName : 'Select Thumbnail Image'}</Text>
                    </TouchableOpacity>
                    {thumbnailUrl && (
                        <View style={styles.imagePreviewContainer}>
                            <Image source={{ uri: thumbnailUrl }} style={styles.imagePreview} />
                            <TouchableOpacity style={styles.deleteButton} onPress={handleDeleteThumbnail}>
                                <Text style={styles.deleteButtonText}>Delete</Text>
                            </TouchableOpacity>
                        </View>
                    )}
                    {thumbnailSuccess ? (
                        <Text style={styles.successMessage}>{thumbnailSuccess}</Text>
                    ) : null}
                </View>

                <Text style={styles.label}>Image</Text>
                <View style={styles.imageContainer}>
                    <TouchableOpacity style={styles.fileInput} onPress={() => handleImagePick(setImageFile, setImageUrl, 'MusicImage', setImageSuccess)} disabled={!title}>
                        <Text>{imageFile ? imageFile.fileName : 'Select Image'}</Text>
                    </TouchableOpacity>
                    {imageUrl && (
                        <View style={styles.imagePreviewContainer}>
                            <Image source={{ uri: imageUrl }} style={styles.imagePreview} />
                            <TouchableOpacity style={styles.deleteButton} onPress={handleDeleteImage}>
                                <Text style={styles.deleteButtonText}>Delete</Text>
                            </TouchableOpacity>
                        </View>
                    )}
                    {imageSuccess ? (
                        <Text style={styles.successMessage}>{imageSuccess}</Text>
                    ) : null}
                </View>

                <Text style={styles.label}>MP3 File</Text>
                <TouchableOpacity style={styles.fileInput} onPress={() => handleFilePick(setMp3File)}>
                    <Text>{mp3File ? mp3File.name : 'Select MP3 File'}</Text>
                </TouchableOpacity>

                <Button title="Upload Podcast" onPress={handleSubmit} disabled={loading} />

                {loading && <ActivityIndicator size="large" color="#0000ff" />}

                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => setModalVisible(false)}
                >
                    <View style={styles.modalContainer}>
                        <View style={styles.modalContent}>
                            <Text style={styles.modalText}>{currentLink}</Text>
                            {currentLink.startsWith('http') && (
                                <TouchableOpacity style={styles.modalButton} onPress={handleCopyToClipboard}>
                                    <Text style={styles.modalButtonText}>Copy to Clipboard</Text>
                                </TouchableOpacity>
                            )}
                            <TouchableOpacity style={styles.modalButton} onPress={() => setModalVisible(false)}>
                                <Text style={styles.modalButtonText}>Close</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </Modal>
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    scrollContainer: {
        flexGrow: 1,
    },
    container: {
        flex: 1,
        padding: 20,
    },
    label: {
        fontSize: 16,
        marginBottom: 5,
    },
    input: {
        height: 50,
        borderColor: '#ccc',
        borderWidth: 1,
        marginBottom: 10,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    fileInput: {
        height: 50,
        borderColor: '#ccc',
        borderWidth: 1,
        marginBottom: 10,
        paddingHorizontal: 10,
        borderRadius: 5,
        justifyContent: 'center',
    },
    imageContainer: {
        marginBottom: 10,
    },
    imagePreviewContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    imagePreview: {
        width: 100,
        height: 100,
        marginBottom: 20,
    },
    profileImage: {
        width: 100,
        height: 100,
        borderRadius: 50,
        marginBottom: 20,
    },
    copyButton: {
        backgroundColor: '#007BFF',
        padding: 10,
        borderRadius: 5,
        marginBottom: 10,
        alignItems: 'center',
        width: 200
    },
    copyButtonText: {
        color: 'white',
    },
    deleteButton: {
        backgroundColor: 'red',
        padding: 5,
        borderRadius: 5,
        marginLeft: 10,
    },
    deleteButtonText: {
        color: 'white',
    },
    successMessage: {
        color: 'green',
        marginTop: 5,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        width: '80%',
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
    modalButton: {
        backgroundColor: '#2196F3',
        padding: 10,
        marginVertical: 5,
        borderRadius: 5,
        width: '100%',
        alignItems: 'center',
    },
    modalButtonText: {
        color: 'white',
    },
});

