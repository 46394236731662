import React, { useState } from 'react';
import { View, TextInput, FlatList, Text, StyleSheet } from 'react-native';
import axios from 'axios';

export default function SearchScreen() {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    const search = async (text) => {
        setQuery(text);
        if (text.length > 2) {
            try {
                const authorsResponse = await axios.get(`https://jiwimusic.fruitnasta.com/mind-cast/api/v1/authors`);
                const podcastsResponse = await axios.get(`https://jiwimusic.fruitnasta.com/mind-cast/api/v1/podcasts`);
                const authors = authorsResponse.data.authors.filter((author) =>
                    author.name.toLowerCase().includes(text.toLowerCase())
                );
                const podcasts = podcastsResponse.data.podcasts.filter((podcast) =>
                    podcast.title.toLowerCase().includes(text.toLowerCase())
                );
                setResults([...authors, ...podcasts]);
            } catch (error) {
                console.error(error);
            }
        } else {
            setResults([]);
        }
    };

    return (
        <View style={styles.container}>
            <TextInput
                style={styles.searchInput}
                placeholder="Search..."
                value={query}
                onChangeText={(text) => search(text)}
            />
            <FlatList
                data={results}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) => (
                    <View style={styles.itemContainer}>
                        <Text style={styles.name}>{item.name || item.title}</Text>
                        <Text>{item.about || item.description}</Text>
                    </View>
                )}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
    },
    searchInput: {
        height: 40,
        borderColor: '#ccc',
        borderWidth: 1,
        paddingHorizontal: 10,
        marginBottom: 10,
        borderRadius: 5,
    },
    itemContainer: {
        marginBottom: 10,
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
    },
    name: {
        fontWeight: 'bold',
    },
});
