import React, { useState } from 'react';
import { View, Text, TextInput, Button, StyleSheet, Image, Picker, Alert } from 'react-native';
import axios from 'axios';

export default function CreateAuthorScreen({ navigation }) {
    const [name, setName] = useState('');
    const [categories, setCategory] = useState('modern');
    const [thumbnailProfileImageURL, setThumbnailProfileImageURL] = useState('https://firebasestorage.googleapis.com/v0/b/musicplayer-4e559.appspot.com/o/singer_pic%2Funknown_singer.png?alt=media&token=0d51216d-476c-4521-841d-d76c58ca462a');
    const [profileImageURL, setProfileImageURL] = useState('https://firebasestorage.googleapis.com/v0/b/musicplayer-4e559.appspot.com/o/singer_pic%2Funknown_singer.png?alt=media&token=0d51216d-476c-4521-841d-d76c58ca462a');
    const [about, setAbout] = useState('santali singer');
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formErrors = {};
        if (!name) formErrors.name = "Name is required";
        if (!categories) formErrors.categories = "Category is required";
        if (!thumbnailProfileImageURL) formErrors.thumbnailProfileImageURL = "Thumbnail URL is required";
        if (!profileImageURL) formErrors.profileImageURL = "Profile URL is required";
        if (!about) formErrors.about = "About is required";
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                const response = await axios.post('https://jiwimusic.fruitnasta.com/mind-cast/api/v1/authors', {
                    name,
                    categories,
                    thumbnailProfileImageURL,
                    profileImageURL,
                    about,
                });
                if (response.data.id) {
                    alert('Author created successfully');
                    setName('');
                    setCategory('modern');
                    setThumbnailProfileImageURL('https://firebasestorage.googleapis.com/v0/b/musicplayer-4e559.appspot.com/o/singer_pic%2Funknown_singer.png?alt=media&token=0d51216d-476c-4521-841d-d76c58ca462a');
                    setProfileImageURL('https://firebasestorage.googleapis.com/v0/b/musicplayer-4e559.appspot.com/o/singer_pic%2Funknown_singer.png?alt=media&token=0d51216d-476c-4521-841d-d76c58ca462a');
                    setAbout('santali singer');
                    setErrors({});
                    setTimeout(() => {
                        navigation.navigate('Authors');
                    }, 3000); // 3-second delay before navigating to Authors screen
                }
            } catch (error) {
                console.error(error);
                alert('Failed to create author');
            }
        } else {
            Alert.alert("Validation Error", "Please fill all fields");
        }
    };

    return (
        <View style={styles.container}>
            <Text style={styles.label}>Name</Text>
            <TextInput
                style={styles.input}
                value={name}
                onChangeText={setName}
            />
            {errors.name && <Text style={styles.errorText}>{errors.name}</Text>}

            <Text style={styles.label}>Category</Text>
            <Picker
                selectedValue={categories}
                style={styles.input}
                onValueChange={(itemValue) => setCategory(itemValue)}
            >
                {['traditional', 'semi traditional', 'modern', 'old', 'romantic', 'sad', 'dong', 'lagne', 'baha', 'sohrai', 'dasai', 'nehor'].map((categories) => (
                    <Picker.Item key={categories} label={categories} value={categories} />
                ))}
            </Picker>
            {errors.categories && <Text style={styles.errorText}>{errors.categories}</Text>}

            <Text style={styles.label}>Thumbnail Profile Image URL</Text>
            <TextInput
                style={styles.input}
                value={thumbnailProfileImageURL}
                onChangeText={setThumbnailProfileImageURL}
            />
            {errors.thumbnailProfileImageURL && <Text style={styles.errorText}>{errors.thumbnailProfileImageURL}</Text>}
            <Image
                style={styles.imagePreview}
                source={{ uri: thumbnailProfileImageURL }}
            />

            <Text style={styles.label}>Profile Image URL</Text>
            <TextInput
                style={styles.input}
                value={profileImageURL}
                onChangeText={setProfileImageURL}
            />
            {errors.profileImageURL && <Text style={styles.errorText}>{errors.profileImageURL}</Text>}
            <Image
                style={styles.imagePreview}
                source={{ uri: profileImageURL }}
            />

            <Text style={styles.label}>About</Text>
            <TextInput
                style={styles.input}
                value={about}
                onChangeText={setAbout}
            />
            {errors.about && <Text style={styles.errorText}>{errors.about}</Text>}

            <Button title="Create Author" onPress={handleSubmit} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
    },
    label: {
        fontSize: 16,
        marginBottom: 5,
    },
    input: {
        height: 40,
        borderColor: '#ccc',
        borderWidth: 1,
        marginBottom: 10,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    imagePreview: {
        width: 100,
        height: 100,
        marginBottom: 20,
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    },
});
