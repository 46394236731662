import React, { useState, useEffect } from 'react';
import { Button } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { auth } from './firebase';
import AuthorsScreen from './screens/AuthorsScreen';
import PodcastsScreen from './screens/PodcastsScreen';
import SearchScreen from './screens/SearchScreen';
import CreateAuthorScreen from './screens/CreateAuthorScreen';
import UploadSongScreen from './screens/UploadSongScreen';
import AuthScreen from './screens/AuthScreen';
import Toast from 'react-native-toast-message';
const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

function AuthorsStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Authors" component={AuthorsScreen} />
      <Stack.Screen name="UploadSongs" component={UploadSongScreen} />
    </Stack.Navigator>
  );
}

function MainTabs({ navigation }) {
  const handleLogout = () => {
    auth.signOut().then(() => {
      navigation.navigate('Auth');
    });
  };

  return (
    <Tab.Navigator
      screenOptions={{
        headerRight: () => (
          <Button onPress={handleLogout} title="Logout" />
        ),
      }}
    >
      <Tab.Screen name="Podcasts" component={PodcastsScreen} />
      <Tab.Screen name="Authors" component={AuthorsStack} />
      <Tab.Screen name="Search" component={SearchScreen} />
      <Tab.Screen name="CreateAuthor" component={CreateAuthorScreen} />
    </Tab.Navigator>
  );
}

export default function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <NavigationContainer>
      {user ? (
        <Stack.Navigator>
          <Stack.Screen name="Main" component={MainTabs} options={{ headerShown: false }} />
        </Stack.Navigator>
      ) : (
        <Stack.Navigator>
          <Stack.Screen name="Auth" component={AuthScreen} options={{ headerShown: false }} />
        </Stack.Navigator>
      )}
      <Toast ref={(ref) => Toast.setRef(ref)} />
    </NavigationContainer>
  );
}
