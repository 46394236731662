import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, FlatList, RefreshControl, StyleSheet, TextInput, Image, TouchableOpacity, Dimensions } from 'react-native';
import axios from 'axios';
import { useFocusEffect } from '@react-navigation/native';

const { width } = Dimensions.get('window');

export default function PodcastsScreen() {
    const [podcasts, setPodcasts] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPodcasts, setFilteredPodcasts] = useState([]);
    const [selectedRating, setSelectedRating] = useState(null);

    const fetchPodcasts = async () => {
        setRefreshing(true);
        try {
            const response = await axios.get('https://jiwimusic.fruitnasta.com/mind-cast/api/v1/podcasts');
            setPodcasts(response.data.podcasts);
            setFilteredPodcasts(response.data.podcasts);
        } catch (error) {
            console.error(error);
        }
        setRefreshing(false);
    };

    useFocusEffect(
        useCallback(() => {
            fetchPodcasts();
        }, [])
    );

    const handleSearch = (query) => {
        setSearchQuery(query);
        filterPodcasts(query, selectedRating);
    };

    const handleRatingFilter = (rating) => {
        setSelectedRating(rating);
        filterPodcasts(searchQuery, rating);
    };

    const clearFilter = () => {
        setSelectedRating(null);
        filterPodcasts(searchQuery, null);
    };

    const filterPodcasts = (query, rating) => {
        let filteredData = podcasts;
        if (query.length > 0) {
            filteredData = filteredData.filter(podcast =>
                podcast.title.toLowerCase().includes(query.toLowerCase()) ||
                podcast.description.toLowerCase().includes(query.toLowerCase())
            );
        }
        if (rating !== null) {
            filteredData = filteredData.filter(podcast => podcast.stars === rating);
        }
        setFilteredPodcasts(filteredData);
    };

    return (
        <View style={styles.container}>
            <Text style={styles.countText}>Total Podcasts: {filteredPodcasts.length}</Text>
            <TextInput
                style={styles.searchInput}
                placeholder="Search podcasts..."
                value={searchQuery}
                onChangeText={handleSearch}
            />
            <View style={styles.filterContainer}>
                {[1, 2, 3, 4, 5].map(rating => (
                    <TouchableOpacity
                        key={rating}
                        style={[
                            styles.filterButton,
                            selectedRating === rating && styles.selectedFilterButton,
                        ]}
                        onPress={() => handleRatingFilter(rating)}
                    >
                        <Text style={styles.filterButtonText}>{rating} Stars</Text>
                    </TouchableOpacity>
                ))}
                <TouchableOpacity
                    style={styles.clearFilterButton}
                    onPress={clearFilter}
                >
                    <Text style={styles.filterButtonText}>Clear Filter</Text>
                </TouchableOpacity>
            </View>
            <FlatList
                data={filteredPodcasts}
                keyExtractor={(item) => item.id}
                numColumns={3}
                columnWrapperStyle={styles.columnWrapper}
                renderItem={({ item }) => (
                    <View style={styles.itemContainer}>
                        <Image style={styles.thumbnail} source={{ uri: item.thumbnailImageURL }} />
                        <View style={styles.textContainer}>
                            <Text style={styles.title}>{item.title}</Text>
                            <Text style={styles.description}>{item.description}</Text>
                            <Text style={styles.details}>Category: {item.category}</Text>
                            <Text style={styles.details}>Duration: {item.duration}</Text>
                            <Text style={styles.details}>Stars: {item.stars}</Text>
                        </View>
                    </View>
                )}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={fetchPodcasts} />
                }
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: width * 0.03,
    },
    countText: {
        fontSize: width * 0.05,
        fontWeight: 'bold',
        marginBottom: width * 0.03,
    },
    searchInput: {
        height: width * 0.1,
        borderColor: '#ccc',
        borderWidth: 1,
        paddingHorizontal: width * 0.03,
        marginBottom: width * 0.03,
        borderRadius: width * 0.02,
    },
    filterContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: width * 0.03,
        flexWrap: 'wrap',
    },
    filterButton: {
        padding: width * 0.02,
        backgroundColor: '#ccc',
        borderRadius: width * 0.02,
        marginRight: width * 0.01,
        marginBottom: width * 0.01,
    },
    selectedFilterButton: {
        backgroundColor: '#007BFF',
    },
    clearFilterButton: {
        padding: width * 0.02,
        backgroundColor: '#F94449',
        borderRadius: width * 0.02,
        marginBottom: width * 0.01,
    },
    filterButtonText: {
        color: '#fff',
        fontSize: width * 0.03,
    },
    itemContainer: {
        flex: 1,
        flexDirection: 'column',
        margin: width * 0.01,
        padding: width * 0.03,
        backgroundColor: '#f9f9f9',
        borderRadius: width * 0.02,
    },
    columnWrapper: {
        justifyContent: 'space-between',
    },
    thumbnail: {
        width: '100%',
        height: width * 0.25,
        marginBottom: width * 0.02,
    },
    textContainer: {
        flex: 1,
        alignItems: 'center',
    },
    title: {
        fontWeight: 'bold',
        fontSize: width * 0.04,
        textAlign: 'center',
    },
    description: {
        fontSize: width * 0.03,
        textAlign: 'center',
    },
    details: {
        fontSize: width * 0.03,
        marginTop: width * 0.01,
        textAlign: 'center',
    },
});
