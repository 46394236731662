// firebase.js

import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAa7zk3kbFiO2moSeI-mteljCW3tPRpPAY",
    authDomain: "jiwimusic-62454.firebaseapp.com",
    projectId: "jiwimusic-62454",
    storageBucket: "jiwimusic-62454.appspot.com",
    messagingSenderId: "37905504822",
    appId: "1:37905504822:web:9fd47722752560366f5697",
    measurementId: "G-16PHJ4287Y"
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();
const auth = firebase.auth();
export { storage, firebase as default, auth };
